import type { ICategory, IBrandedGoodsCategory } from "~/typings/categories";
import type { IRequestState } from "~/typings/state";

export const useCategoriesStore = defineStore("categories", () => {
  const { requestPromoCategories, requestGoodsCategories, requestBrandedGoodsCategories } = categoriesApi();
  const { requestShopCode } = storeToRefs(useStoresStore());

  const promo = ref<ICategory[]>([]);
  const goods = ref<ICategory[]>([]);
  const brandedGoodsCategories = ref<IBrandedGoodsCategory[]>([]);
  const status = reactive<{
    promo: IRequestState;
    goods: IRequestState;
    branded: IRequestState;
  }>({
    promo: "initial",
    goods: "initial",
    branded: "initial",
  });

  const promoRoot = computed<ICategory>(() => ({
    id: "",
    key: PROMO_ROOT_KEY,
    name: "Акции и скидки",
    code: "root",
    url: `${Routes.PromoCatalog}?shopCode=${requestShopCode.value}`,
    preview: "/images/catalog-header/sales.webp",
    icon: "/images/promo-catalog-preview.png",
    parentKey: null,
    children: [],
  }));
  const goodsRoot = computed<ICategory>(() => ({
    id: "",
    key: GOODS_ROOT_KEY,
    name: "Все категории",
    code: "root",
    url: `${Routes.Catalog}?shopCode=${requestShopCode.value}`,
    preview: "/images/catalog-header/all-goods.webp",
    parentKey: null,
    children: [],
  }));
  const structure = computed<ICategory[]>(() => [
    { ...promoRoot.value, children: promo.value },
    ...goods.value,
  ]);
  const db = computed<Record<ICategory["key"], ICategory>>(() => {
    const result: Record<ICategory["key"], ICategory> = {};
    function inlineCategories(categories: ICategory[]) {
      categories.forEach((i) => {
        result[i.key] = i;
        if (i.children.length) {
          inlineCategories(i.children);
        }
      });
    }

    result[promoRoot.value.key] = { ...promoRoot.value, children: promo.value };
    inlineCategories(promo.value);

    result[goodsRoot.value.key] = { ...goodsRoot.value, children: goods.value };
    inlineCategories(goods.value);

    return result;
  });

  const fetchPromoCategories = async () => {
    status.promo = "pending";
    try {
      const data = await requestPromoCategories();
      promo.value = data || [];
      status.promo = "success";
    } catch {
      status.promo = "error";
    }
  };

  const fetchGoodsCategories = async () => {
    status.goods = "pending";
    try {
      const data = await requestGoodsCategories();
      goods.value = data;
      setBrandedDB();
      status.goods = "success";
    } catch {
      status.goods = "error";
    }
  };

  const checkPromoCategories = async (force: boolean = false) => {
    if (promo.value.length && !force) {
      return;
    } else {
      await fetchPromoCategories();
    }
  };

  const checkGoodsCategories = async (force: boolean = false) => {
    if (goods.value.length && !force) {
      return;
    } else {
      await fetchGoodsCategories();
    }
  };

  const requestCategories = async (force: boolean = false) => {
    await Promise.all([checkPromoCategories(force), checkGoodsCategories(force)]);
  };

  const getKeyById = (id: ICategory["id"], type: "promo" | "goods"): string => {
    if (!id) {
      id = "0000";
    }
    return `${type === "promo" ? "p" : "g"}${id}`;
  };

  const getCategoryByKey = (key: ICategory["key"]): ICategory | null => {
    return db.value[key] || null;
  };

  const getCategoryById = (id: ICategory["id"], type: "promo" | "goods"): ICategory | null => {
    const key = getKeyById(id, type);
    return getCategoryByKey(key);
  };

  const getParents = (key: ICategory["key"]): ICategory[] => {
    let result: ICategory[] = [];

    const current = getCategoryByKey(key);

    if (current) {
      result = [current];

      if (current.parentKey) {
        const parents = getParents(current.parentKey);

        if (parents.length) {
          result = [...parents, ...result];
        }
      }
    }

    return result;
  };

  const getParentsByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    if (!current || !current.parentKey) {
      return [];
    }

    const parent = getCategoryByKey(current.parentKey);

    if (!parent) {
      return [];
    }

    return getParents(parent.key);
  };

  const getParentsById = (id: ICategory["id"], type: "promo" | "goods"): ICategory[] => {
    const key = getKeyById(id, type);

    return getParentsByKey(key);
  };

  const getChildrenByKey = (key: ICategory["key"]): ICategory[] => {
    const current = getCategoryByKey(key);
    let result: ICategory[] = [];

    if (current) {
      result = [current];

      if (current.children) {
        const childs = current.children.reduce<ICategory[]>((res, child) => {
          const next = getChildrenByKey(child.key);
          return [...res, ...next];
        }, []);

        if (childs.length) {
          result = [...result, ...childs];
        }
      }
    }

    return result;
  };

  const getChildrenById = (id: ICategory["id"], type: "promo" | "goods"): ICategory[] => {
    const key = getKeyById(id, type);

    return getChildrenByKey(key);
  };

  const getTypeByKey = (key: ICategory["key"]): "promo" | "goods" => {
    return key[0] === "p" ? "promo" : "goods";
  };

  const getTreeNameByKey = (key: ICategory["key"]): string => {
    const current = getCategoryByKey(key);
    if (!current) {
      return "";
    }
    const arrayCategoryNames = getParentsByKey(current.key).map((i) => i.name);
    arrayCategoryNames.push(current.name);

    return arrayCategoryNames.join("/");
  };

  const getBrandedGoodsCategories = async () => {
    status.branded = "pending";
    try {
      const data = await requestBrandedGoodsCategories();
      brandedGoodsCategories.value = data ?? [];
      setBrandedDB();
      status.branded = "success";
    } catch {
      status.branded = "error";
    }
  };

  const setBrandedDB = () => {
    brandedGoodsCategories.value.forEach((i) => {
      if (db.value[`g${i.goodsCategory}`]) {
        db.value[`g${i.goodsCategory}`].preview = i.publicImageURL;
        db.value[`g${i.goodsCategory}`].yaSlotConfig = i.adfoxPayload;
        db.value[`g${i.goodsCategory}`].yaSlotDisclaimer = i.disclaimer;
      }
    });
  };

  const getCategoryAscendingTree = (category: ICategory["id"][]) => {
    const current = getCategoryById(category[0], "goods");
    const tree = getParentsById(category[0] || "", "goods");
    if (current) tree.push(current);
    return [...tree.map((b) => ({ to: b.url, title: b.name }))];
  };

  const getCategoryDescendants = (category: ICategory["id"][]) => {
    const tree = getChildrenById(category[0] || "", "goods");
    return [...tree.map((b) => Number(b.id))];
  };

  return {
    db,
    structure,
    promo,
    goods,
    status,
    requestCategories,
    getCategoryByKey,
    getCategoryById,
    getParentsByKey,
    getParentsById,
    getChildrenByKey,
    getChildrenById,
    getTypeByKey,
    getTreeNameByKey,
    getBrandedGoodsCategories,
    getCategoryAscendingTree,
    getCategoryDescendants,
  };
});
